import React, {useEffect} from "react";
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs'
import Sectors from "../../components/sectors/Sectors";

const SectorsPage = () => {
  useEffect(() => {
    // Set the browser title
    document.title = 'ñtsendan sectors';
  }, []);
  return (
    <>
      <p></p>
      <Breadcrumbs title="Sectors" link="Sectors" />
      <p></p>
      <p></p>
      <Sectors />
      <p></p>
    </>
  );
};

export default SectorsPage;
