import React from "react";
import TeamAdd from "../../../components/Team/TeamAdd";
const TeamAddPage = () => {
  return (
    <div className='container'>
      <TeamAdd />
    </div>
  );
};

export default TeamAddPage;
