export const Team = [
    {
        "id": 1,
        "name": "Yannick Nkoumou, MBA",
        "email": "yannick.nkoumou@yahoo.fr",
        "phone": "+237691141359",
        "bio": "Yannick is a Senior Social and Behavior Change (SBC) Consultant skilled in leading organizational and societal transformations. He specializes in change management, innovation, leadership development, and policy design. Yannick leverages behavioral science and human-centered design and system thinking to develop strategies that influence social norms and individual behaviors. He is fluent in French and English and holds an MBA in Strategic Management.",
        "photo": "yannick-nkoumou.jpg",
        "position": "Senior Social and Behavior Change (SBC) Consultant - Managing Partner",
        "linkedin": "https://www.linkedin.com/in/yannicknkoumou/",
        "facebook": null,
        "twitter": null,
        "instagram": null,
        "youtube": null
    },
    {
        "id": 5,
        "name": "Joseph Désiré EDOA OKALA ",
        "email": "jedoa@ntsendan.com",
        "phone": "+237000669655",
        "bio": "Joseph Désiré is a seasoned consultant with over 22 years of experience in strategic management, financial engineering, sociology of organizations, and institutional lobbying. He holds a Bachelor of Business Administration in Accounting and Finance, an Executive Master in Business Administration in Strategic Management, and a Certified Financial Markets Specialist certification from Yale University. He is also a dual doctorate scholar with a PhD in Sociology of Organizations and a DBA in Competitive Intelligence. Joseph has authored numerous articles on strategic management and geopolitical issues, and his book \"Financing Plan of Cameroon Economy: ambition or revolution\" was published by Harmattan France in 2018.",
        "photo": "joseph-edoa.jpg",
        "position": "Senior Consultant – Strategic Management, Financial Engineering, Organizational Behavior ",
        "linkedin": "https://www.linkedin.com/in/joseph-okala-6966211a2/",
        "facebook": null,
        "twitter": null,
        "instagram": null,
        "youtube": null
    },
    {
        "id": 6,
        "name": "Jean Daniel Ngan Bilong, MBA ",
        "email": "info@ntsendan.com",
        "phone": null,
        "bio": "Jean Daniel is a Senior Corporate Governance Consultant with expertise in board effectiveness, organizational culture and behaviors, crisis management, diversity and inclusion, strategic planning, and sustainability. He helps organizations assess and optimize their performance, oversees organizational transformations, and develops CSR strategies that drive responsible and ethical growth. Jean Daniel holds an MBA in Strategic Management and has extensive experience advising enterprises across sectors. He is fluent in French and English.",
        "photo": "jean-daniel.jpg",
        "position": "Senior Consultant – Corporate Governance ",
        "linkedin": "https://www.linkedin.com/in/jean-daniel-ngan-bilong-22451763/",
        "facebook": null,
        "twitter": null,
        "instagram": null,
        "youtube": null
    }
]