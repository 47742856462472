import { useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import AnonymousLayout from "../layouts/AnonymousLayout";
import AdminLayout from "../layouts/AdminLayout";

import PostsList from "../pages/posts/PostList";
import PostAdd from "../pages/posts/PostAdd.js";

import TeamAddPage from "../pages/administration/team/TeamAddPage";
import TeamPage from "../pages/team/TeamPage";

import { AboutPage } from "../pages/AboutPage";
import SectorsPage from "../pages/Sectors/SectorsPage";

import ServicesList from "../pages/services/ServicesPage";
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/Login";
import ContactPage from "../pages/ContactPage";
import Page404 from "../pages/Page404";

import CategoryAddPage from "../pages/administration/category/CategoryAddPage";
import CategoryListPage from "../pages/administration/category/CategoryListPage";

import PostAddPage from "../pages/administration/post/PostAddPage";

import ServicesPage from "../pages/services/ServicesPage";
import CreditPage from "../pages/credit/CreditPage.js";

export default function Router() {
  const [isUserConnected, setIsUserConnected] = useState(true);

  return (
    <Routes>
      <Route path="/admin" element={<AdminLayout />}>
        <Route index element={<Navigate to="/admin/services" />} />
        <Route path="services" element={<ServicesList />} />
        <Route path="post-add" element={<PostAdd />} />
        <Route path="categories/add" element={<CategoryAddPage />} />
        <Route path="categories/list" element={<CategoryListPage />} />
        <Route path="posts" element={<PostsList />} />
        <Route path="posts/add" element={<PostAddPage />} />
        <Route path="team/add" element={<TeamAddPage />} />
      </Route>
      <Route element={<AnonymousLayout />}>
        <Route index element={<Navigate to="/home" />} />
        <Route path="404" element={<Page404 />} />
        <Route path="*" element={<Page404 />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/sectors" element={<SectorsPage />} />
        <Route path="/credits" element={<CreditPage />} />
        
      </Route>
    </Routes>
  );
}
