import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Team as TeamMembers } from "../../constants/Team";

const Team = () => {
  const imageLink = `./uploads/images`;
  const [staffMembers, setStaffMembers] = useState([]);
  const loading = false


  useEffect(() => {
    setStaffMembers(TeamMembers)
  }, []);

  // Function to safely render HTML content using dangerouslySetInnerHTML
  const renderHtmlContent = (html) => {
    return { __html: html };
  };

  return (
    <div className="container team">
      <div className="section-title">
        {/* <h2>Team</h2> */}
        <p>
        Our dedicated experts are the driving force behind your achievements. At <span className="ntsendan">ñtsendan</span>, we bring diverse skills, deep experience, and a shared passion for excellence to the table. We're not just professionals; we're your trusted partners in navigating today's complex business landscape.
         Explore the faces of our team and unlock the potential for your success.
        </p>
      </div>

      {loading ? (
        <div className="text-center">
          <i className="fa fa-spinner fa-spin fa-3x" />
          <p>Loading...</p>
        </div>
      ) : (
        <div className="row">
          {staffMembers && staffMembers.length > 0 ? (
            staffMembers.map((staff, index) => (
              <div key={index} className="col-xl-4 col-lg-4 col-md-12">
                <div className="member">
                  <img
                    src={`${imageLink}/${staff.photo}`}
                    className="rounded-image img-fluid"
                    alt={staff.name}
                  />
                  <div className="member-info">
                    <div className="member-info-content">
                      <h4>{staff.name}</h4>
                      <span>{staff.position}</span>
                      <p></p>
                      <div
                        dangerouslySetInnerHTML={renderHtmlContent(staff.bio)}
                      />
                      
                      <div className="social">
                        {staff.facebook && (
                          <a href={staff.facebook}>
                            <i className={`bi bi-facebook`}></i>
                          </a>
                        )}
                        {staff.linkedin && (
                          <a href={staff.linkedin}>
                            <i className={`bi bi-linkedin`}></i>
                          </a>
                        )}
                        {staff.instagram && (
                          <a href={staff.instagram}>
                            <i className={`bi bi-instagram`}></i>
                          </a>
                        )}
                        {staff.twitter && (
                          <a href={staff.twitter}>
                            <i className={`bi bi-twitter`}></i>
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No staff members found.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Team;
