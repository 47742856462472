import React, { useState } from 'react';
import axios from 'axios';
import { ServerUrl } from '../../../constants/Server';
import { toast } from 'react-toastify';

const Newsletter = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const formData = { email };
      await axios.post(`${ServerUrl}/api/users/addsubscriber`, formData);
      toast.success('Subscription successful', {
        position: 'top-right',
        autoClose: 3000,
      });
      setEmail(''); // Clear the input field
    } catch (error) {
      console.error('Error subscribing:', error);
      toast.error('Subscription failed', {
        position: 'top-right',
        autoClose: 3000,
      });
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div className="col-lg-4 col-md-6 footer-newsletter">
      <h4>Our Newsletter</h4>
      <p> Be the first to receive exclusive content, news, and opportunities delivered right to your inbox.</p>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          name="email"
          value={email}
          onChange={handleEmailChange}
        />
        <input type="submit" value="Subscribe" />
      </form>
    </div>
  );
};

export default Newsletter;
