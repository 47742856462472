export const Sectors = [
    {
      "id": 38,
      "text": "<p><strong>Unleash Your People's Power</strong></p><p>Overcome challenges in creating a positive work environment, fostering innovation, and boosting productivity. Our services empower organizations to achieve these goals, creating a productive culture that attracts top talent, fosters innovation, and improves performance.</p><p>Create a positive and productive workplace culture that attracts and retains top talent. Foster innovation and collaboration among employees. Improve productivity and performance.</p><p>By partnering with us, organizations can expect to:</p><ul><li>Create a positive and productive workplace culture that attracts and retains top talent</li><li>Foster innovation and collaboration among employees</li><li>Improve productivity and performance</li></ul>",
      "category_id": 3,
      "video": "",
      "rich_text": "",
      "title": "Business & Management",
      "subtitle": "",
      "intro": "",
      "published": 0,
      "created_at": "2023-10-31T18:04:00.000Z",
      "updated_at": "2023-12-14T16:46:29.000Z",
      "url": "business-and-management.jpg"
    },
    {
      "id": 39,
      "text": "<p><strong>Better Healthcare Access</strong></p><p>Our consultancy provides tailored solutions to public health challenges. Reduce infectious diseases, improve maternal and child health, and adopt a One Health approach. Achieve better public health outcomes with evidence-based interventions.</p><p>Reduce the burden of infectious diseases through effective prevention and control strategies. Improve maternal, newborn, and child health outcomes, ensuring healthier families and communities. </p><p>By partnering with us, organizations and governments can achieve:</p><ul><li>Reduced burden of infectious diseases through effective prevention and control strategies</li><li>Improved maternal, newborn, and child health outcomes, ensuring healthier families and communities</li><li>A One Health approach that addresses the interconnectedness of human, animal, and environmental health for sustainable well-being</li></ul>",
      "category_id": 3,
      "video": "",
      "rich_text": "",
      "title": "Health",
      "subtitle": "",
      "intro": "",
      "published": 0,
      "created_at": "2023-10-31T18:05:31.000Z",
      "updated_at": "2023-12-14T17:13:05.000Z",
      "url": "Health.jpg"
    },
    {
      "id": 40,
      "text": "<p><strong>Sustainable Future Partnership</strong></p><p>Navigate environmental challenges with our consultancy. Achieve reduced environmental footprint, resilience to climate change, and transition to sustainable consumption. Promote a circular economy and sustainability.</p><p>Reduce environmental footprint through efficient resource use, waste minimization, and pollution control strategies. Enhance resilience to climate change impacts through mitigation and adaptation measures.</p><p>By partnering with us, organizations and governments can achieve:</p><ul><li>Reduced environmental footprint through efficient resource use, waste minimization, and pollution control strategies</li><li>Enhanced resilience to climate change impacts through mitigation and adaptation measures</li><li>Transition to sustainable consumption patterns, promoting a circular economy and reducing environmental impact</li></ul>",
      "category_id": 3,
      "video": "",
      "rich_text": "",
      "title": "Environment",
      "subtitle": "",
      "intro": "",
      "published": 0,
      "created_at": "2023-10-31T18:07:01.000Z",
      "updated_at": "2023-12-14T17:29:03.000Z",
      "url": "environment.jpg"
    },
    {
      "id": 41,
      "text": "<p><strong>Cultivating Sustainable Food Systems</strong></p><p>Transform food systems toward sustainability. Our consultancy offers solutions for sustainable food production, improved food security, and enhanced nutrition. Achieve sustainability and equity in global food systems.</p><p>Improve food security and livelihoods for vulnerable populations, ensuring access to nutritious food. Enhance nutrition and food safety, promoting public health and well-being.</p><p>By partnering with us, organizations and governments can achieve:</p><ul><li>Sustainable food production practices that protect the environment and conserve resources</li><li>Improved food security and livelihoods for vulnerable populations, ensuring access to nutritious food</li><li>Enhanced nutrition and food safety, promoting public health and well-being</li></ul>",
      "category_id": 3,
      "video": "",
      "rich_text": "",
      "title": "Food Systems",
      "subtitle": "",
      "intro": "",
      "published": 0,
      "created_at": "2023-10-31T18:08:19.000Z",
      "updated_at": "2023-12-14T18:05:25.000Z",
      "url": "Food-system.jpg"
    },
    {
      "id": 42,
      "text": "<p><strong>Empowering Effective Governance</strong></p><p>Our public sector consultancy specializes in crafting customized strategies to enhance policymaking, drive successful advocacy initiatives, and elevate governance standards. By implementing effective and well-informed policymaking, we address societal needs and promote sustainable development. Our expertise extends to leading successful advocacy campaigns that advance critical causes, securing necessary resources along the way. Additionally, we focus on improving public and local governance, fostering transparency, accountability, and responsiveness to citizens' needs.</p><p>By partnering with us, governments and public sector organizations can achieve:</p><ul><li>Effective and well-informed policymaking that addresses societal needs and promotes sustainable development</li><li>Successful advocacy campaigns that advance critical causes and secure necessary resources</li><li>Improved public and local governance, ensuring transparency, accountability, and responsiveness to citizens</li></ul>",
      "category_id": 3,
      "video": "",
      "rich_text": "",
      "title": "Public Sector",
      "subtitle": "",
      "intro": "",
      "published": 0,
      "created_at": "2023-10-31T18:09:43.000Z",
      "updated_at": "2023-12-16T08:30:10.000Z",
      "url": "public-sector.jpg"
    },
    {
      "id": 43,
      "text": "<p><strong>Advancing Human Rights</strong></p><p>Our human rights consultancy is dedicated to providing tailored solutions in the realms of gender equality, access to justice, and economic and social rights. We strive to achieve a society that is just and equitable, ensuring effective justice and upholding economic and social rights for all. As part of our commitment, we work towards building a society where gender equality is not only upheld but where women's rights are fully realized.</p><p>By partnering with us, organizations and governments can achieve:</p><ul><li>A more just and equitable society where gender equality is upheld and women's rights are fully realized</li><li>An accessible and effective justice system that ensures fair and timely redress for all citizens</li><li>Upholding of economic and social rights, ensuring access to basic necessities and opportunities for all</li></ul>",
      "category_id": 3,
      "video": "",
      "rich_text": "",
      "title": "Human Rights",
      "subtitle": "",
      "intro": "",
      "published": 0,
      "created_at": "2023-10-31T18:11:30.000Z",
      "updated_at": "2023-12-16T08:33:18.000Z",
      "url": "human-rights.jpg"
    }
  ];
  