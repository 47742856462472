import React from "react";
import Newsletter from "./Newsletter";

const Links = () => {
  return (
    <>
      <div className="col-lg-2 col-md-6 footer-links">
        <h4>Useful Links</h4>
        <ul>
          <li>
            <i className="bi bi-chevron-right"></i> <a href="#/home">Home</a>
          </li>
          <li>
            <i className="bi bi-chevron-right"></i> <a href="#/about">About us</a>
          </li>
          <li>
            <i className="bi bi-chevron-right"></i> <a href="#/sectors">Sectors</a>
          </li>
          <li>
            <i className="bi bi-chevron-right"></i> <a href="#/services">Services</a>
          </li>
          <li>
            <i className="bi bi-chevron-right"></i>{" "}
            <a href="#/credits">Credits</a>
          </li>
          <li>
            <i className="bi bi-chevron-right"></i>{" "}
            <a href="#">Privacy policy</a>
          </li>
        </ul>
      </div>

      <div className="col-lg-3 col-md-6 footer-links">
        <h4>Our Services</h4>
        <ul>
          <li>
            <i className="bi bi-chevron-right"></i> <a href="#/services">Social and Behavior Change (SBC)</a>
          </li>
          <li>
            <i className="bi bi-chevron-right"></i>{" "}
            <a href="#/services">Organization Design</a>
          </li>
          <li>
            <i className="bi bi-chevron-right"></i>{" "}
            <a href="#/services">Leadership and Culture</a>
          </li>
          <li>
            <i className="bi bi-chevron-right"></i>{" "}
            <a href="#/services">Strategy and Policy Design</a>
          </li>
          <li>
            <i className="bi bi-chevron-right"></i>{" "}
            <a href="#/services">Innovation Management</a>
          </li>
          <li>
            <i className="bi bi-chevron-right"></i>{" "}
            <a href="#/services">Business Development</a>
          </li>
          <li>
            <i className="bi bi-chevron-right"></i>{" "}
            <a href="#/services">Financial Engineering and Fundraising </a>
          </li>
          <li>
            <i className="bi bi-chevron-right"></i>{" "}
            <a href="#/services">Training and Capacity Building</a>
          </li>
        </ul>
      </div>
      <Newsletter />
    </>
  );
};

export default Links;
