import React, {useEffect} from "react";
import Breadcrumbs from "./../components/breadcrumbs/Breadcrumbs";
import About from "../components/about/About";

export const AboutPage = () => {
  useEffect(() => {
    // Set the browser title
    document.title = 'About ñtsendan';
  }, []); 
  return (
    <>
      <p></p>
      <Breadcrumbs title="About ñtsendan" link="About" />
      <About />
      <p></p>
    </>
  );
};
