import React from "react";

const Credit = () => {
  return (
    <div className="container team">
      <div className="section-title">
        {/* <h2>Team</h2> */}
        <p>
          Our dedicated experts are the driving force behind your achievements.
          At <span className="ntsendan">ñtsendan</span>, we bring diverse
          skills, deep experience, and a shared passion for excellence to the
          table. We're not just professionals; we're your trusted partners in
          navigating today's complex business landscape. Explore the faces of
          our team and unlock the potential for your success.
        </p>
      </div>
      <div className="row">
        <div className="col-xl-4 col-lg-4 col-md-12">
          <div className="member">
            <div className="member-info">
              <div className="member-info-content">
                <h4>Graphic design</h4>
                <span>Michel AMBASSA</span>
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-12">
          <div className="member">
            <div className="member-info">
              <div className="member-info-content">
                <h4>Web development</h4>
                <span>
                  <a href="https://www.linkedin.com/in/thierry-nomo-166ba8272/">Thierry NOMO</a>
                </span>
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-12">
          <div className="member">
            <div className="member-info">
              <div className="member-info-content">
                <h4>Photos</h4>
                <span>
                  <a href="https://pexel.com">Pexels</a>
                </span>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Credit;
