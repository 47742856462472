import React from "react";
import HeroCarousel from "../components/carousel/HeroCarousel";
import AboutIntro from "../components/about/AboutIntro";
import SectorsIntro from "../components/sectors/SectorsIntro";
import "bootstrap/dist/css/bootstrap.css";
import ServicesStatic from "../components/services/ServicesStatic";
import ContactForm from "../components/contact/ContactForm";
const HomePage = () => {
  const carouselInterval = 6000;
  return (
    <>
      <section id="hero">
        <HeroCarousel interval={carouselInterval} />
      </section>
      <main id="main">
        <section id="about" className="about">
          <div className="row no-gutters">
            <div className="col-xl-6 ps-0 ps-lg-6 pe-lg-1 d-flex align-items-stretch">
              <AboutIntro />
            </div>
            <div className="col-xl-6 ps-0 ps-lg-6 pe-lg-1 d-flex align-items-stretch">
            <SectorsIntro />
            </div>
          </div>
        </section>
        <section id="services" className="services">
          <ServicesStatic />
        </section>
        <section id="contact" className="contact">
          <ContactForm />
        </section>
      </main>
    </>
  );
};

export default HomePage;
