import React from 'react';
import CategoryAddForm from './../../../components/category/CategoryAddForm'; // Adjust the import path as needed

const CategoryAddPage = () => {
  return (
    <div className='container'>
      <h1>Add Category</h1>
      <CategoryAddForm />
    </div>
  );
};

export default CategoryAddPage;
