import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ServerUrl } from '../../constants/Server';
import '../../assets/css/list.css';

const CategoryList = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Fetch categories from the API
    axios
      .get(`${ServerUrl}/api/categories/list`)
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);

        // Show an error toast notification
        toast.error('Error fetching categories', {
          position: 'top-right',
          autoClose: 3000,
        });
      });
  }, []);

  const handleEdit = (categoryId) => {
    // Implement your edit logic here
    console.log(`Editing category with ID ${categoryId}`);
  };

  const handleDelete = (categoryId) => {
    // Implement your delete logic here
    console.log(`Deleting category with ID ${categoryId}`);
  };

  return (
    <div>
      <h1>Category List</h1>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <tr key={category.id}>
              <td>{category.name}</td>
              <td>{category.description}</td>
              <td>
                <button onClick={() => handleEdit(category.id)}>Edit</button>
                <button onClick={() => handleDelete(category.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CategoryList;
