import React from "react";
import Team from "../../components/Team/Team";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
const TeamPage = () => {
  return (
    <>
      <Breadcrumbs title="Team" link="team"/>
      <p></p>
      <Team />
      <p></p>
    </>
  );
};

export default TeamPage;
