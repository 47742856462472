import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { ServerUrl } from "../../constants/Server";
import "../../assets/css/form.css";
import "../../assets/css/post.css";


const PostForm = () => {
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [formData, setFormData] = useState({
    text: "",
    category_id: "",
    video: "",
    rich_text: "",
    title: "",
    subtitle: "",
    intro: "",
    images: [],
    keywords: [],
  });

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${ServerUrl}/api/categories/list`)
      .then((r)=>{setCategoriesOptions(r.data)})
      .catch((error)=>{console.log(error)})
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const images = [...formData.images];
    const file = e.target.files[0];

    if (file) {
      // Read the selected file as a data URL and add it to the images array
      const reader = new FileReader();
      reader.onload = (event) => {
        images.push({
          preview: event.target.result,
          file,
        });
        setFormData({
          ...formData,
          images,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteImage = (index) => {
    const images = [...formData.images];
    images.splice(index, 1);
    setFormData({
      ...formData,
      images,
    });
  };
  const axiosConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${ServerUrl}/api/posts/add`, formData, axiosConfig);
      console.log("Post added:", response.data);

      // Show success toast notification
      toast.success("Post added successfully", {
        position: "top-right",
        autoClose: 3000, // Close the notification after 3 seconds
      });

      // Reset the form fields if needed
      setFormData({
        text: "",
        category_id: "",
        video: "",
        rich_text: "",
        title: "",
        subtitle: "",
        intro: "",
        images: [],
        keywords: [],
      });
      // Handle success or navigation to another page
    } catch (error) {
      console.error("Error adding post:", error);

      // Show error toast notification
      toast.error("Error adding post", {
        position: "top-right",
        autoClose: 3000,
      });
      // Handle error
    }
  };

  return (
    <div>
      <h2>Add a New Post</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
          />
        </div>

        <div>
          <label htmlFor="subtitle">Subtitle</label>
          <input
            type="text"
            id="subtitle"
            name="subtitle"
            value={formData.subtitle}
            onChange={handleChange}
          />
        </div>

        <div>
          <label htmlFor="intro">Introduction</label>
          <textarea
            id="intro"
            name="intro"
            value={formData.intro}
            onChange={handleChange}
          />
        </div>

        <div>
          <label htmlFor="text">Text</label>
          <textarea
            id="text"
            name="text"
            value={formData.text}
            onChange={handleChange}
          />
        </div>

        <div>
          <label htmlFor="rich_text">Rich Text</label>
          <textarea
            id="rich_text"
            name="rich_text"
            value={formData.rich_text}
            onChange={handleChange}
          />
        </div>

        <div>
          <label htmlFor="video">Video</label>
          <input
            type="text"
            id="video"
            name="video"
            value={formData.video}
            onChange={handleChange}
          />
        </div>

        <div>
          <label htmlFor="category_id">Category</label>
          <select
            id="category_id"
            name="category_id"
            value={formData.category_id}
            onChange={handleChange}
          >
            <option value="">Select Category</option>
            {categoriesOptions.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        {/* Add input fields for images */}
        <div>
          <label htmlFor="images">Images</label>
          <input
            type="file"
            id="images"
            name="images"
            accept="image/*"
            onChange={handleImageChange}
            multiple
          />
        </div>
        <div className="image-preview">
          {formData.images.map((image, index) => (
            <div key={index}>
              <img src={image.preview} alt={`Image ${index + 1}`} />
              <button
                type="button"
                onClick={() => handleDeleteImage(index)}
              >
                Delete
              </button>
            </div>
          ))}
        </div>

        {/* Add more input fields for keywords here */}

        <button type="submit">Add Post</button>
      </form>
    </div>
  );
};

export default PostForm;
