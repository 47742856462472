import React from 'react'
import PostForm from '../../../components/post/PostForm'

const PostAddPage = () => {
  return (
    <div className='container'>
      <h1>Add a Post</h1>
      <PostForm />
    </div>
  )
}

export default PostAddPage