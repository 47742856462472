import React from "react";

const EnterpriseDetails = () => {
  return (
    <div className="col-lg-3 col-md-6">
      <div className="footer-info">
        <h3>ñtsendan</h3>
        <p>
        A108 Ongola Street, <br />
        Yaounde, Cameroon
          <br />
          <br />
          <strong>Phone:</strong> +237 691 1413 59
          <br />
          <strong>Email:</strong> info@ntsendan.com
          <br />
        </p>
        <div className="social-links mt-3">
          <a href="#" className="twitter">
            <i className="bi bi-twitter"></i>
          </a>
          <a href="#" className="facebook">
            <i className="bi bi-facebook"></i>
          </a>

          <a href="#" className="linkedin">
            <i className="bi bi-linkedin"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default EnterpriseDetails;
