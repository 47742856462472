import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import slide1 from "../../assets/images/slide/hero-3.jpg";
import slide2 from "../../assets/images/slide/street-girls.jpg";
import slide3 from "../../assets/images/slide/hero-1.jpg";

const HeroCarousel = ({ interval }) => {
  const [index, setIndex] = useState(0); // Définir 'index' en tant qu'état initial
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const data = [
    {
      image: slide1,
      title: "Transforming behaviors, Shaping futures",
      description:
        "At ñstendan, we believe in the transformative power of change. We harness transdisciplinary expertise from Strategic Management, Social Sciences, Humanities, and Holistic Thinking to craft innovative solutions tailored to our clients' unique needs.",
    },
    {
      image: slide2,
      title: "Pioneering Progress, Building Tomorrow",
      description:
        "Social innovation at ñtsendan goes beyond the ordinary, crafting ingenious solutions that drive meaningful change, foster sustainable progress, and shape a brighter future for all.",
    },
    {
      image: slide3,
      title: "Unlocking the Power of Digital Transformation",
      description:
        "ñtsendan leads the way in harnessing cutting-edge technologies to revolutionize industries, streamline processes, and accelerate success in the digital age.",
    },
  ];

  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
      {data.map((slide, i) => {
        return (
          <Carousel.Item key={i}>
            <img
              className="d-block w-100"
              src={slide.image}
              alt={slide.title}
            />
            <Carousel.Caption>
              <h2>{slide.title}</h2>
              <p>{slide.description}</p>
              <div>
                <a
                  href="#/services"
                  className="btn-readmore animate__animated animate__fadeInUp scrollto"
                >
                  Read More
                </a>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export default HeroCarousel;
