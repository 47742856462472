import React from "react";

const ServicesStatic = () => {
  return (
    <>
      
        <div className="container">
          <div className="section-title">
            <h2>Services</h2>
            <p>
            Explore our range of services, driven by our mission of performance improvement and anchored in our values
             of collaboration, innovation, and impact, to create a world where everyone thrives
            </p>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 icon-box">
              <div className="icon">
                <i className="bi bi-briefcase"></i>
              </div>
              <h4 className="title">
                <a href="">Social and Behavior Change (SBC)</a>
              </h4>
              <p className="description">
              Our SBC services help you understand the factors that influence behavior ...
              </p>
            </div>
            <div className="col-lg-4 col-md-6 icon-box">
              <div className="icon">
                <i className="bi bi-card-checklist"></i>
              </div>
              <h4 className="title">
                <a href="">Organization Design</a>
              </h4>
              <p className="description">
              Unleashing organizational potential through strategic design
              </p>
            </div>
            <div className="col-lg-4 col-md-6 icon-box">
              <div className="icon">
                <i className="bi bi-bar-chart"></i>
              </div>
              <h4 className="title">
                <a href="">Leadership and Culture</a>
              </h4>
              <p className="description">
              Building a culture that thrives
              </p>
            </div>
            <div className="col-lg-4 col-md-6 icon-box">
              <div className="icon">
                <i className="bi bi-binoculars"></i>
              </div>
              <h4 className="title">
                <a href="">Strategy and Policy Design</a>
              </h4>
              <p className="description">
              Transforming strategies into tangible outcomes
              </p>
            </div>
            <div className="col-lg-4 col-md-6 icon-box">
              <div className="icon">
                <i className="bi bi-brightness-high"></i>
              </div>
              <h4 className="title">
                <a href="">Innovation Management</a>
              </h4>
              <p className="description">
              Transforming ideas into reality 
              </p>
            </div>
            <div className="col-lg-4 col-md-6 icon-box">
              <div className="icon">
                <i className="bi bi-calendar4-week"></i>
              </div>
              <h4 className="title">
                <a href="">Business Development</a>
              </h4>
              <p className="description">
              Unlocking growth opportunities 
              </p>
            </div>
          </div>
        </div>
    </>
  );
};

export default ServicesStatic;
