import React from "react";
import FrontNav from "./nav/FrontNav";
import Logo from "../../assets/images/logo.png"

const Header = () => {
  return (
    <>
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex align-items-center justify-content-between">
          {/* <h1 className="logo">
            <a href="index.html">Ñtsendan</a>
          </h1> */}

          <a href="index.html" className="logo"><img src={Logo} alt="" className="img-fluid" /></a> 

          <FrontNav />
        </div>
      </header>
    </>
  );
};

export default Header;
