import React from "react";

const About = () => {
  return (
    <>
      <div className="container about">
        <div className="row no-gutters">
          <div className="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-lg-start"></div>
          <div className="col-xl-7 ps-0 ps-lg-5 pe-lg-1 d-flex align-items-stretch">
            <div className="content d-flex flex-column justify-content-center">
              <h3>
                At ñtsendan, we believe Africa's future lies in Africa's hands.
              </h3>

              <div className="row">
                <div className="col-md-12 col-lg-12 icon-box">
                  <p>
                    As an African consulting firm, we transcend paradigms to
                    tackle complex challenges. We draw on transdisciplinary
                    insights from diverse fields to help leaders shape change
                    that lasts.
                  </p>

                  <p>
                    Our human-centered approach engages stakeholders to
                    co-create solutions tailored to African realities. We
                    optimize strategies, policies and innovations to benefit
                    society, economy and environment simultaneously.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-lg-12 icon-box">
                  {" "}
                  <p>
                    We are committed to Africa's equitable and sustainable
                    growth. Our expertise in social and behavior change helps
                    clients shift mindsets, empower communities and enact
                    systemic transformation. If you aim to drive positive change
                    on the continent, we invite you to join us in writing
                    Africa's next chapter. The story is waiting to be told.
                    Let's tell it
                  </p>
                  together.
                  <p>
                    If you aim to drive positive change on the continent, we
                    invite you to join us in writing Africa's next chapter. The
                    story is waiting to be told. Let's tell it together.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
