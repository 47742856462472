import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../components/header/Header'
import FooterComponent from '../components/footer/FooterComponent'

const AnonymousLayout = () => {
  return (
    <>
        <Header />
        <Outlet />
        <FooterComponent />
    </>
  )
}

export default AnonymousLayout