export const Services = [
    {
        "id": 26,
        "text": "<p><strong>Unlock Behavior for Positive Change</strong></p><p>We empower organizations to influence human behavior positively, promoting sustainable practices, healthier lifestyles, and active civic participation. Our Social and Behavior Change (SBC) services offer a comprehensive approach to understanding and shaping human behavior. By partnering with us for SBC services, organizations can achieve:</p>              <ul>         <li>Enhanced understanding of the factors that influence human behavior, enabling effective interventions</li>         <li>Design and implementation of behavior-centered programs that promote sustainable consumption, healthy living, and civic engagement</li>         <li>Measurable impact on social and environmental indicators, demonstrating the effectiveness of SBC interventions</li>       </ul>              <p>Join us in fostering positive social and environmental change through tailored strategies that address the root of behavioral patterns and contribute to a more sustainable and engaged society.</p>",
        "category_id": 2,
        "video": "",
        "rich_text": "",
        "title": "Social and Behavior Change (SBC)",
        "subtitle": "",
        "intro": "",
        "published": 0,
        "created_at": "2023-10-17T14:32:35.000Z",
        "updated_at": "2023-12-16T08:35:59.000Z",
        "url": "social-behaviour.jpg"
      },
      
      {
        "id": 27,
        "text": "<p><strong>Social Innovation for Lasting Impact</strong></p>\r\n<p>Social Innovation is at the heart of creating lasting impact. We specialize in developing innovative solutions that drive positive change for individuals and communities. Our holistic approach involves:</p>\r\n<ul>\r\n  <li><strong>Strategic Solutions:</strong> Crafting effective and sustainable strategies for social impact.</li>\r\n  <li><strong>Creative Collaboration:</strong> Fostering partnerships and collaborations that amplify innovative ideas.</li>\r\n  <li><strong>Community Empowerment:</strong> Implementing initiatives that empower communities and foster long-term positive outcomes.</li>\r\n  <li><strong>Impact Measurement:</strong> Utilizing metrics and evaluation to measure and enhance the effectiveness of our social innovation initiatives.</li>\r\n</ul>",
        "category_id": 2,
        "video": "",
        "rich_text": "",
        "title": "Social Innovation",
        "subtitle": "",
        "intro": "",
        "published": 0,
        "created_at": "2023-10-17T14:33:20.000Z",
        "updated_at": "2023-10-17T14:33:20.000Z",
        "url": "social-innovation.jpg"
      }

  ,
      {
        "id": 30,
        "text": "<p><strong>Unleashing Organizational Potential through Strategic Design</strong></p><p>Our Organization Design services provide a comprehensive approach to aligning an organization's structure, culture, and goals, \r\nenabling them to achieve their full potential. We collaborate closely with clients to assess their current state, identify areas for improvement,\r\n and develop a customized plan that harmonizes their organizational design with their strategic objectives.</p>\r\n<p>By partnering with us for Organization Design services, organizations can achieve:</p>\r\n<ul>\r\n<li>A clear understanding of their strengths, weaknesses, and strategic direction, enabling informed decision-making</li>\r\n<li>A well-defined structure that supports efficient operations, promotes collaboration, and aligns with organizational goals</li>\r\n<li>A positive and engaging culture that attracts, retains, and motivates top talent</li>\r\n</ul>",
        "category_id": 2,
        "video": "",
        "rich_text": "",
        "title": "Organizational Design",
        "subtitle": "",
        "intro": "",
        "published": 0,
        "created_at": "2023-10-17T14:47:51.000Z",
        "updated_at": "2023-12-16T08:41:18.000Z",
        "url": "organisational-design.jpg"
      },
      {
        "id": 31,
        "text": "<p><strong>Digital Transformation</strong></p>\r\n<p>Embark on a transformative journey with our Digital Transformation services, where we collaborate closely with clients to develop and implement strategies for adopting new technologies tailored to their business needs. Our approach, rooted in a profound understanding of the digital transformation process, offers the following key benefits:</p>\r\n<ul>\r\n  <li>Customized strategies for seamless adoption of emerging technologies</li>\r\n  <li>Strategic guidance in navigating the complexities of digital transformation</li>\r\n  <li>Maximization of benefits through tailored technology integration</li>\r\n</ul>",
        "category_id": 2,
        "video": "",
        "rich_text": "",
        "title": "Digital Transformation",
        "subtitle": "",
        "intro": "",
        "published": 0,
        "created_at": "2023-10-17T14:48:29.000Z",
        "updated_at": "2023-10-17T14:48:29.000Z",
        "url": "digital-transformation.jpg"
      }
      
      ,
      
      {
        "id": 33,
        "text": "<p><strong>We work collaboratively with clients to gather, analyze, and interpret data, providing valuable insights into their markets and informing strategies for success.</strong></p>\r\n<p>Our profound understanding of market research principles empowers us to guide you in making well-informed decisions about your business. With a dedicated focus on market analysis and research, we offer the following key services:</p>\r\n<ul>\r\n  <li>Comprehensive market assessments to identify opportunities and challenges</li>\r\n  <li>In-depth competitor analysis for strategic positioning</li>\r\n  <li>Data-driven insights to support informed decision-making</li>\r\n  <li>Strategic guidance in developing and refining business strategies</li>\r\n</ul>",
        "category_id": 2,
        "video": "",
        "rich_text": "",
        "title": "Market Analysis and Research",
        "subtitle": "",
        "intro": "",
        "published": 0,
        "created_at": "2023-10-17T14:49:48.000Z",
        "updated_at": "2023-10-17T14:49:48.000Z",
        "url": "market-analysis.jpg"
      }
      ,
    {
        "id": 34,
        "text": "<p><strong>Building a culture that thrives</strong></p><p>Our Leadership and Culture services provide comprehensive solutions to address these challenges and empower organizations to achieve their full potential. We offer a range of tailored training and coaching programs that focus on developing effective leaders, managing change successfully, and building a culture of trust, respect, and collaboration.</p>\r\n\r\n<p>By partnering with us for Leadership and Culture services, organizations can achieve:</p>\r\n\r\n<ul>\r\n  <li>A cadre of inspiring and empowering leaders who drive innovation, engagement, and performance</li>\r\n  <li>Effective change management strategies that minimize disruption and maximize positive outcomes</li>\r\n  <li>A positive and supportive workplace culture that attracts, retains, and motivates top talent</li>\r\n</ul>\r\n",
        "category_id": 2,
        "video": "",
        "rich_text": "We help develop leaders who inspire and empower their teams. We provide training and coaching on topics such as leadership development, change management, and team building. We also help organizations create a culture of trust, respect, and collaboration.",
        "title": "Leadership and Culture",
        "subtitle": "",
        "intro": "",
        "published": 0,
        "created_at": "2023-10-31T17:27:59.000Z",
        "updated_at": "2023-12-16T08:43:18.000Z",
        "url": "leadership-and-culture.jpg"
    },
    {
        "id": 35,
        "text": "<p><strong>Empowering your workforce through transformative training</strong></p>\r\n<p>Organizations often struggle to identify and address skill and knowledge gaps, hindering their ability to achieve objectives and adapt to evolving challenges. Our Training and Capacity Building services provide a comprehensive approach to equipping organizations with a skilled and knowledgeable workforce. We offer training programs covering topics such as social and behavior change, organization design, leadership and culture, strategy and policy design, innovation management, and business development. We work closely with organizations to assess training needs, develop customized programs, and ensure effective delivery.</p>\r\n<ul>\r\n  <li>Helping organizations develop the skills and knowledge their team needs to succeed.</li>\r\n  <li>Providing training on a wide range of topics, including social and behavior change, organization design, leadership and culture, strategy and policy design, innovation management, and business development.</li>\r\n  <li>Working with organizations to develop and implement training programs that are tailored to their specific needs.</li>\r\n</ul>",
        "category_id": 2,
        "video": "",
        "rich_text": "We help organizations develop the skills and knowledge their team needs to succeed. We provide training on a wide range of topics, including social and behavior change, organization design, leadership and culture, strategy and policy design, innovation management, and business development. We also work with organizations to develop and implement training programs that are tailored to their specific needs.",
        "title": "Training and Capacity Building",
        "subtitle": "",
        "intro": "",
        "published": 0,
        "created_at": "2023-10-31T17:33:52.000Z",
        "updated_at": "2023-12-16T09:10:42.000Z",
        "url": "training-and-capacity-building.jpg"
      }    
,      
    {
        "id": 36,
        "text": "<p><strong>Unlocking growth opportunities</strong></p>\r\n\r\n<p>Organizations often grapple with challenges in achieving growth, from identifying market opportunities to executing effective business development strategies. Our Business Development services provide tailored solutions, including market research, strategic planning, and sales support, empowering organizations to expand operations and achieve sustainable growth.</p>\r\n\r\n<p>By partnering with us for Business Development services, organizations can achieve:</p>\r\n\r\n<ul>\r\n  <li>In-depth market insights and analysis that inform strategic decision-making</li>\r\n  <li>Well-defined business development strategies aligned with organizational goals and objectives</li>\r\n  <li>Enhanced sales and marketing capabilities that drive lead generation, customer acquisition, and revenue growth</li>\r\n</ul>\r\n",
        "category_id": 2,
        "video": "",
        "rich_text": "",
        "title": "Business Development",
        "subtitle": "",
        "intro": "",
        "published": 0,
        "created_at": "2023-10-31T17:35:44.000Z",
        "updated_at": "2023-12-16T09:02:35.000Z",
        "url": "business-development.jpg"
    },
    {
        "id": 37,
        "text": "<p><strong>Transforming ideas into reality</strong></p>\r\n\r\n<p>Organizations often struggle to foster innovation, facing challenges in nurturing talent, translating ideas into prototypes, and establishing effective processes for commercialization. Our Innovation Management services offer a comprehensive solution, providing tailored training programs and coaching to stimulate ideation, develop prototyping strategies, and streamline the launch of new products or services.</p>\r\n\r\n<p>By partnering with us for Innovation Management services, organizations can achieve:</p>\r\n\r\n<ul>\r\n  <li>A thriving culture of innovation that encourages creativity, collaboration, and risk-taking</li>\r\n  <li>A systematic approach to identifying, evaluating, and developing promising ideas</li>\r\n  <li>Effective prototyping strategies that transform ideas into tangible concepts</li>\r\n</ul>\r\n",
        "category_id": 2,
        "video": "",
        "rich_text": "",
        "title": "Innovation Management",
        "subtitle": "",
        "intro": "",
        "published": 0,
        "created_at": "2023-10-31T17:37:39.000Z",
        "updated_at": "2023-12-16T09:01:17.000Z",
        "url": "innovation-management.jpg"
    },
    {
        "id": 45,
        "text": "<p><strong>Unlocking investment opportunities for private and public entities</strong></p>\r\n\r\n<p>Securing funding is a common challenge for organizations, especially in mobilizing humanitarian funds or seeking international investment. Our Financial Engineering and Fundraising services provide tailored solutions, addressing issues such as business plan development and transforming intangible assets into viable financial instruments. We specialize in unlocking funding potential for private entities, public bodies, and governments.</p>\r\n\r\n<p>By partnering with us for Financial Engineering and Fundraising services, organizations can achieve:</p>\r\n\r\n<ul>\r\n  <li>Secured funding for their projects and initiatives, enabling them to pursue their missions and make a positive impact</li>\r\n  <li>Professionally crafted business plans and project frameworks that effectively present their value proposition and secure investor interest</li>\r\n  <li>Access to investment capital from international financial platforms, fueling growth and expansion</li>\r\n</ul>\r\n",
        "category_id": 2,
        "video": "",
        "rich_text": "",
        "title": "Financial Engineering and Fundraising",
        "subtitle": "",
        "intro": "",
        "published": 0,
        "created_at": "2023-12-16T09:08:11.000Z",
        "updated_at": "2023-12-16T09:08:11.000Z",
        "url": "performance-improvement.jpg"
    }
]