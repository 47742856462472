import React from "react";
import AboutIntroImg from "../../assets/images/about-intro.jpg";
const AboutIntro = () => {
  return (
    <>
      <div className="about-intro">
        <figure>
          <img className="scaled" src={AboutIntroImg} alt="About ñtsendan" />
          <figcaption>
            <a href="#/about" className="figcaption-link">
              About Us
            </a>
          </figcaption>
        </figure>
        <div className="intro">
          <p>
            <b>
              At ñtsendan, we believe Africa's future lies in Africa's hands{" "}
            </b>
            As an African consulting firm, we transcend paradigms to tackle
            complex challenges. We draw on transdisciplinary insights from
            diverse fields to help leaders shape change that lasts.
          </p>
          <a
            href="#/about"
            className="btn-readmore animate__animated animate__fadeInUp scrollto"
          >
            Read More
          </a>
        </div>
      </div>
    </>
  );
};

export default AboutIntro;
