import React from "react";
import SectorsIntroImg from "../../assets/images/sectors-intro.jpg";

const SectorsIntro = () => {
  return (
    <>
      <div className="about-intro">
        <figure>
          <img className="scaled" src={SectorsIntroImg} alt="About ñtsendan" />
          <figcaption><a href="#/sectors" className="figcaption-link">Sectors</a></figcaption>
        </figure>
        <div className="intro">
          <p>
            Discover our diverse sectors of expertise, all guided by our
            unwavering commitment to excellence and deeply rooted in our core
            principles of synergy, forward thinking, and influence. Together, we
            shape a future where every sector flourishes.
          </p>
          <a
            href="#/sectors"
            className="btn-readmore animate__animated animate__fadeInUp scrollto"
          >
            Read More
          </a>
        </div>
      </div>
    </>
  );
};

export default SectorsIntro;
