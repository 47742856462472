import React, {useEffect} from 'react'
import ServicesDetails from '../../components/services/ServicesDetails'
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs'
const ServicesPage = () => {
  useEffect(() => {
    // Set the browser title
    document.title = 'ñtsendan services';
  }, []);
  return (
    
    <>
    <p></p>
      <Breadcrumbs title="Services" link="Services" />
      <p></p>
      <p></p>
      <ServicesDetails />
      <p></p>
    </>
  )
}

export default ServicesPage