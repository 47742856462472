import React, {useEffect} from "react";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import Credit from "../../components/credit/Credit";

const CreditPage = () => {
  useEffect(() => {
    // Set the browser title
    document.title = 'ñtsendan credits';
  }, []); 
  return (
    <>
      <p></p>
      <Breadcrumbs title="Credit" link="Credits" />
      <Credit />
      <p></p>
    </>
  );
};
export default CreditPage
