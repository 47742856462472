import React, { useState } from "react";
import axios from "axios";
import { ServerUrl } from "../../constants/Server";
import { toast } from "react-toastify";

const TeamAdd = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    position: "",
    linkedin: "",
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
    bio: "",
    photo: null, // For file input
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      photo: file,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const {
      name,
      email,
      phone,
      position,
      linkedin,
      facebook,
      twitter,
      instagram,
      youtube,
      bio,
      photo,
    } = formData;

    const data = new FormData();
    data.append("name", name);
    data.append("email", email);
    data.append("phone", phone);
    data.append("position", position);
    data.append("linkedin", linkedin);
    data.append("facebook", facebook);
    data.append("twitter", twitter);
    data.append("instagram", instagram);
    data.append("youtube", youtube);
    data.append("bio", bio);
    data.append("photo", photo); // Append the file

    try {
      await axios.post(`${ServerUrl}/api/staff/add`, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type for file uploads
        },
      });

      // Clear the form after successful submission
      setFormData({
        name: "",
        email: "",
        phone: "",
        position: "",
        linkedin: "",
        facebook: "",
        twitter: "",
        instagram: "",
        youtube: "",
        bio: "",
        photo: null,
      });

      // Show success toast notification
      toast.success("Post added successfully", {
        position: "top-right",
        autoClose: 3000, // Close the notification after 3 seconds
      });
    } catch (error) {
      console.error("Error adding staff member:", error);
      // Show success toast notification
      toast.error("Error adding Staff", {
        position: "top-right",
        autoClose: 3000, // Close the notification after 3 seconds
      });
    }
  };

  return (
    <div>
      <h2>Add a Team Member</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Phone:</label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Position:</label>
          <input
            type="text"
            name="position"
            value={formData.position}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>LinkedIn:</label>
          <input
            type="text"
            name="linkedin"
            value={formData.linkedin}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Facebook:</label>
          <input
            type="text"
            name="facebook"
            value={formData.facebook}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Twitter:</label>
          <input
            type="text"
            name="twitter"
            value={formData.twitter}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Instagram:</label>
          <input
            type="text"
            name="instagram"
            value={formData.instagram}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>YouTube:</label>
          <input
            type="text"
            name="youtube"
            value={formData.youtube}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Bio:</label>
          <textarea name="bio" value={formData.bio} onChange={handleChange} />
        </div>
        <div>
          <label>Photo:</label>
          <input
            type="file"
            name="photo"
            accept=".jpg, .jpeg, .png"
            onChange={handleFileChange}
          />
        </div>
        <button type="submit">Add Member</button>
      </form>
    </div>
  );
};

export default TeamAdd;
