import React, { useState, useEffect } from "react";
import parse from 'html-react-parser';
import { Services } from "../../constants/Services";


const ServicesDetails = () => {
  const [posts, setPosts] = useState([]);
  const imageLink = `./uploads/images`;

  useEffect(() => {
    setPosts(Services)
  }, []);

  return (
    <div className="container serviceslist">
      <div className="section-title">
        {/* <h2>Services Details</h2> */}
        <p>Explore our services in detail.</p>
      </div>

      {posts.map((post, index) => (
        <div className={`row service-item ${index % 2 === 0 ? "even" : "odd"}`}>
          <div
            className={`col-md-6 service-item-image ${
              index % 2 === 0 ? "left" : "right"
            }`}
          >
            <figure>
              <img src={`${imageLink}/${post.url}`} alt={post.title} />
              <figcaption>{post.title}</figcaption>
            </figure>
          </div>

          <div
            className={`col-md-6 service-item-text ${
              index % 2 === 0 ? "right" : "left"
            }`}
          >
            {parse(post.text)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ServicesDetails;
