import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ServerUrl } from '../../constants/Server';

function CategoryAddForm() {
  const [categoryData, setCategoryData] = useState({
    name: '',
    description: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCategoryData({ ...categoryData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Send a POST request to add the new category
      await axios.post(`${ServerUrl}/api/categories/add`, categoryData);

      setCategoryData({ name: '', description: '' });

      toast.success('Category added successfully!', {
        position: 'top-right',
        autoClose: 3000, // Close the toast after 3 seconds
      });
    } catch (error) {
      console.log(error)
      toast.error('Error adding category. Please try again.', {
        position: 'top-right',
        autoClose: 3000,
      });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Category Name</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            value={categoryData.name}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea
            className="form-control"
            id="description"
            name="description"
            value={categoryData.description}
            onChange={handleInputChange}
            required
          ></textarea>
        </div>

        <button type="submit" className="btn btn-primary">
          Add Category
        </button>
      </form>
    </div>
  );
}

export default CategoryAddForm;
