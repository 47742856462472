import React from 'react'

const Breadcrumbs = ({title, link}) => {
  return (
    <section className="breadcrumbs">
    <div className="container">

      <div className="d-flex justify-content-between align-items-center">
        
        <ol>
          <li><a href="index.html">Home</a></li>
          <li>{link}</li>
        </ol>
      </div>

    </div>
  </section>
  )
}

export default Breadcrumbs