import React from "react";
import EnterpriseDetails from "./footerTop/EnterpriseDetails";
import Newsletter from "./footerTop/Newsletter";
import Copyright from "./footerBottom/Copyright";
import Links from "./footerTop/Links";

const FooterComponent = () => {
  return (
    <>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <EnterpriseDetails />
              <Links />
            </div>
          </div>
        </div>
        <Copyright />
      </footer>
    </>
  );
};

export default FooterComponent;
