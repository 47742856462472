import React, { useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { useLocation, Link } from "react-router-dom";

const FrontNav = () => {
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();

  const toggleNavbar = () => {
    setExpanded(!expanded);
  };

  return (
    <Navbar
      expand="lg"
      expanded={expanded}
      className={`navbar ${expanded ? "active" : ""}`}
    >
      <Navbar.Toggle
        className="mobile-nav-button"
        onClick={toggleNavbar}
        aria-controls="responsive-navbar-nav"
      />

      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          <Navbar.Brand href="#/home">Home</Navbar.Brand>
          <Nav.Link href="/#/about">About</Nav.Link>
          <Nav.Link href="/#/sectors">Sectors</Nav.Link>
          <Nav.Link href="/#/services">Services</Nav.Link>
          <Nav.Link href="/#/team">Team</Nav.Link>
          {location.pathname === "/home" ? ( // Check if you are on the Home page
            <ScrollLink
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="nav-link"
            >
              Contact
            </ScrollLink>
          ) : (
            <Link to="/home" className="nav-link">
              Contact
            </Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default FrontNav;
