import React from 'react'
import { Outlet } from 'react-router-dom'


 const AdminLayout = () => {
  return (
    <>
        <div>Admin Header</div>
        <div>AdminLayout</div>
        <Outlet />
    </>
    
  )
}
export default AdminLayout
