import React from 'react';

const Copyright = () => {
  return (
    <div className="container">
      <div className="copyright">
        &copy; Copyright <strong><span>ñtsendan</span></strong> {new Date().getFullYear()}. All Rights Reserved
      </div>
      <div className="credits">
        <a href="#/credits">Credits</a>
      </div>
    </div>
  );
}

export default Copyright;
